import { template as template_812e746bf6894e89b72ea87fbd8e9dfe } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_812e746bf6894e89b72ea87fbd8e9dfe(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
