import { template as template_9f8433dd263f4278b5479d605dadc117 } from "@ember/template-compiler";
const FKText = template_9f8433dd263f4278b5479d605dadc117(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
