import { template as template_db1625dbf5dd4d25959c29a6133739fb } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ActivityCell = template_db1625dbf5dd4d25959c29a6133739fb(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="activity"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="activity"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActivityCell;
