import { template as template_af2629dd706047a7bc2e8ab529671df1 } from "@ember/template-compiler";
const FKLabel = template_af2629dd706047a7bc2e8ab529671df1(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
